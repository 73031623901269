<template>
	<div v-loading="initLoading" class="WalletRecharge">
		<div class="tit fs16 fw-7 flex align-center mt32">
			<img class="mr8" src="@/assets/list/item-con-tip.png" />
			充值金额
		</div>
		<!-- 选择种类 start -->
		<div class="change flex flex-wrap mt24">
			<div
				v-for="item in arrs"
				:key="item.id"
				@click="(cur = item), (money = item.add_money)"
				:class="{ active: item.id == cur.id }"
				class="item p16 pr00 cursor text-c mr24 fs18 font-33 br8 mb24"
			>
				{{ item.add_money }}元
			</div>
		</div>
		<!-- 选择种类 end -->

		<div class="tit fs16 fw-7 flex align-center mt8">
			<img class="mr8" src="@/assets/list/item-con-tip.png" />
			自定输入金额
		</div>

		<!-- 输入框 start -->
		<div class="input flex align-center ptb24">
			<span class="fs24 font-33">￥</span
			><input
				@keyup="
					decimalPoint($event, {
						num: 2,
						val: 'money',
					})
				"
				v-model="money"
				:placeholder="'0.00'"
				class="fs24 pl16"
				type="number"
			/>
		</div>
		<!-- 输入框 end -->

		<div class="tit fs16 fw-7 flex align-center mt32">
			<img class="mr8" src="@/assets/list/item-con-tip.png" />
			选择支付方式
		</div>

		<!-- 选择 账户 start -->
		<div class="type pt32">
			<div class="flex">
				<div
					@click="checked = 'wx'"
					class="item flex align-center justify-between cursor"
				>
					<div class="left fs16 font-33 flex align-center">
						<img src="@/assets/public/wallet-wx.png" />微信
					</div>
					<div class="right">
						<img
							:src="
								checked == 'wx'
									? require('@/assets/public/checked.png')
									: require('@/assets/public/unchecked.png')
							"
						/>
					</div>
				</div>
			</div>
			<div class="flex" style="padding-top: 40px">
				<div
					@click="checked = 'ali'"
					class="item flex align-center justify-between cursor"
				>
					<div class="left fs16 font-33 flex align-center">
						<img src="@/assets/public/wallet-ali.png" />支付宝
					</div>
					<div class="right">
						<img
							:src="
								checked == 'ali'
									? require('@/assets/public/checked.png')
									: require('@/assets/public/unchecked.png')
							"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- 选择 账户 end -->

		<div class="btn flex justify-center">
			<el-button :loading="loading" @click="btn">充值</el-button>
		</div>

		<PayAlert
			ref="payAlert"
			:order.sync="payAlertObj"
			@operate="operate"
		></PayAlert>
	</div>
</template>
<script>
import decimalPoint from "@/config/decimalPoint";
import PayAlert from "@/components/PayAlert";
export default {
	name: "WalletRecharge",
	mixins: [decimalPoint],
	components: {
		PayAlert,
	},
	data() {
		return {
			initLoading: true,
			checked: "wx", // 当前的选择
			money: "",
			loading: false, // 接口请求中

			cur: {}, // 当前的类别
			arrs: [], // 充值类别
			payAlertObj: {
				order_sn: "",
				type: "",
				money: "",
			},
		};
	},
	created() {
		this.getData();
	},
	methods: {
		// 支付完成
		operate() {
			this.$emit("operate", {
				type: "recharge",
			});
		},
		btn() {
			let { money, checked } = this;

			let pay_type = "wxpaypc";
			if (checked == "ali") pay_type = "alipaypc";

			if (!money || money == 0) {
				this.$message.error("请输入充值金额");
				return false;
			}

			this.loading = true;

			// 生成订单
			this.$API
				.postAddOrder({
					order_type: 1,
					pay_type,
					order_money: money,
					// product_id: cur.id,
				})
				.then((res) => {
					this.loading = false;

					if (res.code == 1) {
						this.payAlertObj = {
							order_sn: res.data.order_sn,
							type: checked == "ali" ? "支付宝" : "微信",
							money,
						};
						this.$nextTick(() => {
							this.popupFun(true, "payAlert");
						});
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("ERROR", err);
				});
		},

		// 获取配置信息
		getData() {
			this.$API
				.getRechargeRule()
				.then((res) => {
					if (res.code == 1) {
						this.arrs = res.data;
						this.cur = res.data[0];
						this.money = res.data[0].add_money;

						this.initLoading = false;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		/**
		 * ! 是否显示弹窗
		 * @param {sign} sign 显示隐藏 true 显示 false 隐藏
		 */
		popupFun(sign, ref) {
			if (sign) {
				try {
					this.$refs[ref].open();
				} catch (error) {
					this.$refs[ref].show();
				}
			} else {
				try {
					this.$refs[ref].close();
				} catch (error) {
					this.$refs[ref].hide();
				}
			}
		},
	},
};
</script>
<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
input[type="number"] {
	-moz-appearance: textfield;
}

.WalletRecharge {
	@extend .w100;
	@extend .h100;

	.tit {
		img {
			width: 18px;
			height: 11px;
		}
	}

	.change {
		width: 720px;

		.item {
			min-width: 206px;
			border: 1px solid #eeeeee;
			align-items: baseline;

			&:after {
				content: "";
				display: none;
				width: 32px;
				height: 32px;
				background: url("../assets/imgs/wallet-recharge-change-icon.png");
				background-size: 100% 100%;
				content: " ";
				position: absolute;
				top: 0;
				right: 0;
			}

			&.active {
				background-color: #fff5eb;
				border-color: #ff8300;

				&:after {
					display: block;
				}
			}
		}
	}

	.input {
		border-bottom: 1px solid #eeeeee;

		input {
			width: 500px;
		}
	}

	.type {
		.item {
			width: 300px;

			.left {
				img {
					width: 32px;
					height: 32px;
					margin-right: 12px;
				}
			}

			.right {
				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	.btn {
		margin-top: 88px;

		button {
			padding: 0;
			margin: 0;
			border: none;
			text-align: center;
			width: 240px;
			height: 48px;
			line-height: 48px;
			background: #ff8300;
			border-radius: 24px;
			font-size: 16px;
			font-weight: 400;
			color: #ffffff;
		}
	}
}
</style>
