<template>
	<div class="PayAlert">
		<el-dialog
			:visible.sync="visible"
			center
			width="648px"
			@close="close"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<div class="container">
				<div class="nav fs16 fw-7 font-33 pb24 pr00 flex">
					<div class="tit-line flex">
						<span class="pr">确定支付信息</span>
						<span class="line">确定支付信息</span>
					</div>
				</div>

				<div class="con flex fs16 font-33">
					<div class="left">
						<div>订单编号：{{ order.order_sn }}</div>
						<div>
							付款金额：<span>￥{{ order.money }}元</span>
						</div>
						<div>支付方式：{{ order.type }}支付</div>
					</div>
					<div class="right">
						<div class="code flex">
							{{ order.type }}支付
							<img
								v-loading="loading"
								:src="obj"
								v-if="order.type == '支付宝'"
							/>
							<img
								v-loading="loading"
								:src="obj.url"
								v-if="order.type == '微信'"
							/>
						</div>
						<div class="fs12 mt8">打开手机支付，扫一扫继续支付</div>
					</div>
				</div>

				<div class="btn flex align-center justify-center">
					<div
						@click="visible = false"
						class="flex align-center justify-center"
					>
						返回
					</div>
					<div @click="paySuccessBtn" class="flex align-center justify-center">
						支付完成
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: "PayAlert",
	props: {
		order: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			visible: false,
			loading: false,

			obj: {}, // 支付数据
			payTimer: null, // 支付的轮询
			payClearTimer: false, // 是否清除定时器轮询
		};
	},
	created() {},
	methods: {
		// 点击支付完成
		paySuccessBtn() {
			let {
				order: { order_sn },
			} = this;

			this.$API
				.payCallback({
					order_sn: order_sn,
				})
				.then((res) => {
					if (res.code == 1) {
						if (res.data.is_pay == 1) {
							this.$message.success("支付成功");
							this.payClearTimer = true;
							this.$emit("operate", {});
							this.close();
						} else {
							this.$message.warning("未支付");
						}
					} else {
						console.log(res.msg);
						this.payClearTimer = true;
					}
				})
				.catch((err) => {
					console.log("err", err);
					this.payClearTimer = true;
				});
		},
		// 支付回调
		payCallback() {
			clearTimeout(this.payTimer);
			let {
				order: { order_sn },
				payClearTimer,
			} = this;

			if (order_sn && !payClearTimer) {
				this.payTimer = setTimeout(() => {
					clearTimeout(this.payTimer);
					this.$API
						.payCallback({
							order_sn: order_sn,
						})
						.then((res) => {
							if (res.code == 1) {
								if (res.data.is_pay == 1) {
									this.$message.success("支付成功");
									clearTimeout(this.payTimer);
									this.$emit("operate", {});
									this.close();
								} else {
									!payClearTimer && this.payCallback();
								}
							} else {
								console.log(res.msg);
								this.payClearTimer = true;
							}
						})
						.catch((err) => {
							console.log("err", err);
							this.payClearTimer = true;
						});
				}, 2000);
			}
		},
		// 生成支付宝二维码
		AlipayCode() {
			this.loading = true;
			let {
				order: { order_sn },
			} = this;
			this.$API
				.AlipayCode({
					order_sn,
					alipay_method: "native",
				})
				.then((res) => {
					this.loading = false;
					if (res.code == 1) {
						this.obj = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 生成微信二维码
		generateWxCode() {
			this.loading = true;
			let {
				order: { order_sn },
			} = this;

			this.$API
				.generateWxCode({
					order_sn,
					wxpaytype: "native",
				})
				.then((res) => {
					this.loading = false;
					if (res.code == 1) {
						this.obj = res.data;
						this.payCallback();
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		open() {
			this.payClearTimer = false;
			console.log(this.order);
			if (this.order.type == "支付宝") {
				this.AlipayCode();
			}
			if (this.order.type == "微信") {
				this.generateWxCode();
			}

			this.visible = true;
		},
		close() {
			this.payClearTimer = true;

			if (this.visible) {
				this.visible = false;
			}
		},
	},
};
</script>
<style scoped lang="scss">
.PayAlert {
	@extend .w100;
	@extend .h100;

	.container {
		padding: 48px 50px 40px 48px;

		.con {
			.left {
				flex: 1;
				padding-right: 20px;

				div {
					margin-bottom: 24px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				span {
					color: #f03c3b;
				}
			}

			.right {
				padding-left: 40px;
				border-left: 1px solid #eeeeee;

				.code {
					img {
						margin-left: 16px;
						width: 96px;
						height: 96px;
					}
				}
			}
		}

		.btn {
			margin-top: 50px;

			div {
				cursor: pointer;
				width: 200px;
				height: 48px;
				background: #f6f7f9;
				border-radius: 24px;
				font-size: 16px;
				color: #333333;

				&:last-child {
					margin-left: 72px;
					background-color: #ff8300;
					color: #ffffff;
				}
			}
		}
	}
}
</style>
