<template>
	<div class="WalletBindAli">
		<div class="tit fs16 fw-7 flex align-center mt24">
			<img class="mr8" src="@/assets/list/item-con-tip.png" />
			第一步：先获取手机验证码
		</div>

		<div class="fs16 font-66 mt28">绑定的用户手机{{ userInfo.mobile }}</div>
		<div class="item flex align-center">
			<span class="item-name">验证码</span>
			<div class="item-div flex">
				<el-input
					@change="btn"
					style="flex: 1"
					v-model="code"
					maxlength="6"
					placeholder="请输入验证码"
					clearable
				/>
				<button @click="getVerify" class="ml16">
					{{ codeText }}
				</button>
			</div>
		</div>

		<div class="tit fs16 fw-7 flex align-center mt32">
			<img class="mr8" src="@/assets/list/item-con-tip.png" />
			第二步：上传支付宝收款二维码
		</div>

		<div class="item head flex align-center">
			<div class="item-div">
				<el-upload
					:accept="'.png,.jpg,.jpeg'"
					:action="'/api' + $API.uploadFile"
					list-type="picture"
					:show-file-list="false"
					:on-success="onSuccess"
					:before-upload="beforeUpload"
				>
					<img
						:src="qrcode || require('@/assets/imgs/wallet-bind-icon.png')"
						class="change"
					/>
				</el-upload>
			</div>
		</div>

		<div class="tit fs16 fw-7 flex align-center mt24">
			<img class="mr8" src="@/assets/list/item-con-tip.png" />
			第三步：填写支付宝真实姓名
		</div>

		<div class="item flex align-center">
			<span class="item-name">真实姓名</span>
			<div class="item-div flex">
				<el-input
					@change="btn"
					style="flex: 1"
					v-model="name"
					maxlength="6"
					placeholder="请输入真实姓名"
					clearable
				/>
			</div>
		</div>

		<div class="item flex align-center">
			<span class="item-name"></span>
			<div class="item-div btn">
				<el-button :loading="loading" @click="btn">提交</el-button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	name: "WalletBindAli",
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
		codeText() {
			let { date } = this;
			if (date == 60) {
				return "获取验证码";
			} else {
				return date + "S 后重新获取";
			}
		},
	},
	data() {
		return {
			codeLoading: false, // 是否获取验证码中
			code: "", // 验证码
			loading: false, // 是否请求数据中
			Timer: null, // 定时器
			date: 60, // 时间

			qrcode: "",
			name: "",
		};
	},
	created() {
		this.getData();
	},
	methods: {
		async btn() {
			let { userInfo, loading, code, name, qrcode } = this;
			let { _mobile } = userInfo;

			if (loading) {
				return false;
			}

			if (!code || code.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}

			if (!qrcode) {
				this.$message.info("收款码未上传");
				return false;
			}

			let reg = /^[\u0391-\uFFE5]+$/;
			if (!reg.test(name)) {
				this.$message.info("姓名输入有误");
				return false;
			}

			this.loading = true;

			let hasCode = await this.verifyCode();
			if (!hasCode) {
				return false;
			}

			this.$API
				.postBindWithdrawAccount({
					true_name: name,
					qrcode: qrcode,
					account_type: 2,
					is_default: 0,
					account_id: _mobile,
				})
				.then((res) => {
					this.loading = false;

					if (res.code == 1) {
						this.$message.success("绑定完成");
						this.$emit("operate", {});
						this.$emit("toBack", {});
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 校验验证码是否正确
		async verifyCode() {
			let { userInfo, code } = this;
			let { _mobile: mobile } = userInfo;
			// 请求接口时携带的数据
			let data = {
				mobile: mobile,
				code: code,
				type: 6,
			};
			let res = await this.$API.postUserCheckVerifyCode(data);
			console.log(res);
			if (res.code == 1) {
				return true;
			} else {
				this.loading = false;
				this.$message.info(res.msg);
				return false;
			}
		},
		// 获取验证码
		getVerify() {
			let { userInfo, codeLoading, date } = this;
			let { _mobile: mobile, id: user_id } = userInfo;

			if (codeLoading || date != 60) {
				return false;
			}

			this.codeLoading = true;

			this.$API
				.SendVerifyCode({
					mobile,
					type: 6,
					user_id,
				})
				.then((res) => {
					if (res.code == 1) {
						this.Timer = setInterval(() => {
							this.date = this.date - 1;

							if (this.date <= 0) {
								this.date = 0;
								clearInterval(this.Timer);
								this.date = 60;
							}
						}, 1000);

						if (this.$store.state.ISDEV == 1) {
							this.code = res.data.code.toString();
						}

						this.$message.success("发送成功");
						this.codeLoading = false;
					} else {
						this.$message.error(res.msg);
						this.codeLoading = false;
					}
				})
				.catch((err) => {
					this.codeLoading = false;
					console.log("err", err);
				});
		},
		// 上传完成
		onSuccess(e) {
			this.qrcode = e.data[0].path;
		},
		// 上传之前
		beforeUpload(file) {
			const isPNG = file.type === "image/png";
			const isJPG = file.type === "image/jpg";
			const isJPEG = file.type === "image/jpeg";

			const isLt10M = file.size / 1024 / 1024 < 10;

			if (!isPNG && !isJPG && !isJPEG) {
				this.$message.error("上传图片只能是 PNG或者JPG或者JPG 格式!");
			}
			if (!isLt10M) {
				this.$message.error("上传图片大小不能超过 10MB!");
			}
			return (isPNG || isJPG || isJPEG) && isLt10M;
		},

		getData() {
			this.$API
				.getWithdrawAccount({
					account_type: 2, //  1 微信 2 支付宝 3 银行卡。
				})
				.then((res) => {
					if (res.code == 1) {
						this.name = res.data.true_name;
						this.qrcode = res.data.qrcode;
					}
				});
		},
	},
};
</script>
<style scoped lang="scss">
.WalletBindAli {
	@extend .w100;
	@extend .h100;

	.tit {
		img {
			width: 18px;
			height: 11px;
		}
	}

	.item {
		padding-top: 24px;

		.item-name {
			width: 80px;
			font-size: 16px;
			font-weight: 400;
			color: #666666;
		}

		.item-div {
			width: 480px;

			button {
				border: none;
				border-radius: 8px;
				width: 120px;
				height: 40px;
				background-color: #ff8300;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
			}

			&.btn {
				margin-top: 60px;

				button {
					width: 240px;
					height: 48px;
					border-radius: 100px;
				}
			}
		}

		&.head {
			padding-top: 24px;

			.change {
				width: 100px;
				height: 100px;
				overflow: hidden;
				cursor: pointer;
			}
		}
	}
}
</style>
