<template>
	<div v-loading="initLoading" class="WalletWithdraw">
		<div v-if="curType == 'info'">
			<div class="tit fs16 fw-7 flex align-center mt32">
				<img class="mr8" src="@/assets/list/item-con-tip.png" />
				提现金额
			</div>

			<!-- 输入框 start -->
			<div class="input flex align-center ptb24">
				<span class="fs24 font-33">￥</span
				><input
					@keyup="
						decimalPoint($event, {
							num: 2,
							max: curMoney,
							val: 'money',
						})
					"
					v-model="money"
					:placeholder="'账户余额 ' + curMoney"
					class="fs24 pl16"
					type="number"
				/>
			</div>
			<!-- 输入框 end -->

			<!-- 当前金额 start -->
			<div class="flex align-center fs14 font-99 ptb16">
				当前余额￥{{ curMoney }}，提现收取{{ withdraw_handling_fee }}%的手续费。
				<span @click="money = curMoney" class="cursor" style="color: #4177dd"
					>全部提现</span
				>
			</div>
			<!-- 当前金额 end -->

			<div class="tit fs16 fw-7 flex align-center mt32">
				<img class="mr8" src="@/assets/list/item-con-tip.png" />
				到账账户
			</div>

			<!-- 选择 账户 start -->
			<div class="change pt32">
				<div class="flex">
					<div
						@click="checked = 'wx'"
						class="item flex align-center justify-between cursor"
					>
						<div class="left fs16 font-33 flex align-center">
							<img src="@/assets/public/wallet-wx.png" />微信
						</div>
						<div class="right">
							<img
								:src="
									checked == 'wx'
										? require('@/assets/public/checked.png')
										: require('@/assets/public/unchecked.png')
								"
							/>
						</div>
					</div>
				</div>
				<div class="flex" style="padding-top: 40px">
					<div
						@click="checked = 'ali'"
						class="item flex align-center justify-between cursor"
					>
						<div class="left fs16 font-33 flex align-center">
							<img src="@/assets/public/wallet-ali.png" />支付宝
						</div>
						<div class="right">
							<img
								:src="
									checked == 'ali'
										? require('@/assets/public/checked.png')
										: require('@/assets/public/unchecked.png')
								"
							/>
						</div>
					</div>
				</div>
			</div>
			<!-- 选择 账户 end -->

			<!-- 提示 start -->
			<div class="tips flex align-center fs14 font-99">
				<img
					@click="tips = !tips"
					:src="
						tips
							? require('@/assets/public/checked.png')
							: require('@/assets/public/unchecked.png')
					"
				/>提现即默认同意并阅读<span @click="open">《平台提现协议》</span>
			</div>
			<!-- 提示 end -->

			<div class="btn flex justify-center">
				<el-button :loading="loading" @click="btn">立即提现</el-button>
			</div>
		</div>

		<div v-else class="wait flex flex-direction align-center text-c">
			<img src="@/assets/imgs/wallet-withdraw-wait-icon.png" />
			<div class="fs16 fw-7 font-33 mt32">待处理</div>
			<div class="fs16 font-66 mt24">
				申请提交成功，平台审核通过，会将提现金额支付 到您的账户请注意查收
			</div>
		</div>
	</div>
</template>
<script>
import decimalPoint from "@/config/decimalPoint";
export default {
	name: "WalletWithdraw",
	mixins: [decimalPoint],
	data() {
		return {
			curType: "info", // 当前的 info 提现; wait 提现等待;
			initLoading: true,
			tips: false, // 平台提现协议
			curMoney: 0, // 当前余额
			withdraw_handling_fee: 0, // 提现手续费
			checked: "wx", // 当前的选择
			money: "",
			loading: false, // 接口请求中

			body: "", // 规则
			min: 0,
		};
	},
	async created() {
		await this.getData();
	},
	methods: {
		async btn() {
			let { money, min, tips, checked } = this;

			let account_type = 1;
			if (checked == "ali") account_type = 2;

			if (!money || money == 0) {
				this.$message.error("请输入提现金额");
				return false;
			}

			if (min > money) {
				this.$message.error("最小提现金额为 " + min);
				return false;
			}

			if (!tips) {
				this.$message.error("请阅读并勾选提现规则");
				return false;
			}

			this.loading = true;
			let isBindAccount = await this.isBindAccount(account_type);
			if (!isBindAccount) {
				return false;
			}

			this.$API
				.withdraw({
					type: account_type,
					money,
					// pay_password: n.password,
				})
				.then((res) => {
					//	this.curType = "wait";

					this.loading = false;
					if (res.code == 1) {
						this.curType = "wait";
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("ERROR", err);
				});
		},
		// 判断用户是否绑定了提现账户
		isBindAccount(account_type) {
			return new Promise((resolve) => {
				this.$API
					.getWithdrawAccount({
						account_type, //  1 微信 2 支付宝 3 银行卡。
					})
					.then((res) => {
						if (res.code == 0) {
							this.$message.info("您还未绑定账号，请先绑定");
							this.$emit("operate", { checked: this.checked });
							resolve(false);
						} else {
							resolve(true);
						}
					});
			});
		},

		// 获取配置信息
		async getData() {
			// 获取金额信息
			await this.$API
				.getMyMoney()
				.then((res) => {
					if (res.code == 1) {
						// 余额
						this.curMoney = res.data.user_money;
						// 手续费
						this.withdraw_handling_fee = res.data.withdraw_handling_fee;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});

			// 最小提现金额
			await this.$API
				.postLimitWithdraw({
					code: "user.min_withdraw_money",
				})
				.then((res) => {
					if (res.code == 1) {
						this.min = res.data;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});

			this.initLoading = false;

			this.$API
				.getAgreement({
					category_id: 125,
				})
				.then((res) => {
					if (res.code == 1) {
						this.body = res.data.content;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 打开规则弹窗
		open() {
			if (!this.body) {
				this.$message.info("正在获取规则，请稍后再试");
				return false;
			}

			this.$alert(this.body, "提现规则", {
				type: "none",
				center: true,
				showCancelButton: false,
				showConfirmButton: false,
				dangerouslyUseHTMLString: true,
				closeOnClickModal: true,
			});
		},
	},
};
</script>
<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
input[type="number"] {
	-moz-appearance: textfield;
}

.WalletWithdraw {
	@extend .w100;
	@extend .h100;

	.tit {
		img {
			width: 18px;
			height: 11px;
		}
	}

	.input {
		border-bottom: 1px solid #eeeeee;

		input {
			width: 500px;
		}
	}

	.change {
		.item {
			width: 300px;

			.left {
				img {
					width: 32px;
					height: 32px;
					margin-right: 12px;
				}
			}

			.right {
				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	.tips {
		padding-top: 40px;

		img {
			width: 16px;
			height: 16px;
			margin-right: 8px;
			cursor: pointer;
		}

		span {
			cursor: pointer;
			color: #4177dd;
		}
	}

	.btn {
		margin-top: 88px;

		button {
			padding: 0;
			margin: 0;
			border: none;
			text-align: center;
			width: 240px;
			height: 48px;
			line-height: 48px;
			background: #ff8300;
			border-radius: 24px;
			font-size: 16px;
			font-weight: 400;
			color: #ffffff;
		}
	}

	.wait {
		img {
			width: 120px;
			height: 86px;
			margin-top: 100px;
		}

		div {
			max-width: 360px;

			&:last-child {
				line-height: 2em;
			}
		}
	}
}
</style>
