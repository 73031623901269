"use strict";

export default {
	data() {
		return {
			decimalPointTimer: null,
		};
	},
	methods: {
		/**
		 * 感谢  https://www.cnblogs.com/wzjwffg/p/9884022.html
		 * @param {e} e 元素
		 * @param {num} num 小数点后几位  默认2位
		 * @param {val} val 元素的 key 值
		 * @param {min} min 最小值
		 * @param {max} max 最大值
		 */
		decimalPoint(e, opt = {}) {
			clearTimeout(this.decimalPointTimer);
			this.decimalPointTimer = setTimeout(() => {
				let target = e.target || e.currentTarget;
				let { num = 2, val = null, min = -1, max = -1 } = opt;

				if (Object.keys(opt).length == 0 && target) {
					num = target.dataset.num || 2;
					val = target.dataset.val || null;
					min = target.dataset.min || -1;
					max = target.dataset.max || -1;
				}

				if (!val) {
					console.error("使用 decimalPoint 方法，必须写含有 data-val 属性");
					return false;
				}
				let model = this[val];
				model = model.toString();

				if (!model) {
					this[val] = "";
					return false;
				}

				model = model.replace(/[^\d.]/g, ""); // 清除"数字"和"."以外的字符
				model = model.replace(/^\./g, ""); // 验证第一个字符是数字而不是
				model = model.replace(/\.{2,}/g, "."); // 只保留第一个. 清除多余的
				model = model
					.replace(".", "$#$")
					.replace(/\./g, "")
					.replace("$#$", ".");
				if (num == 0) {
					if (model.indexOf(".") != -1) {
						model = model.slice(0, model.indexOf("."));
					}
				} else {
					model = model.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // 只能输入两个小数
				}

				let arr = model.split(".");
				let str1 = arr[0];
				let str2 = arr[1] || "";

				// 去除前置的所有的 0     办法太 low 了 看怎么能优化下
				str1 = str1.replace(/\b(0+)/gi, "");
				str1 ? str1 + "." + str2 : "0." + str2;

				model = Number(model);
				if (min > -1) {
					this[val] = model < min ? min : model;
				} else if (max > -1) {
					this[val] = model > max ? max : model;
				} else {
					this[val] = model;
				}
				clearTimeout(this.decimalPointTimer);
			}, 300);
		},
	},
};
