<template>
	<div class="Wallet bgfff br4 p32">
		<!-- 我的钱包 start -->
		<div v-if="curType == 'purse'" class="purse">
			<!-- 标题 start -->
			<div
				class="tit fs18 fw-7 font-33 pr00 pb24 flex justify-between align-center"
			>
				<div class="tit-line flex">
					<span class="pr">我的钱包</span>
					<span class="line">我的钱包</span>
				</div>
			</div>
			<!-- 标题 end -->

			<!-- 积分 start -->
			<div class="nav pr00 mt24 text-c ptb24">
				<div class="money fs28 fw-7">{{ moeny }}</div>
				<div class="mt16 fs16 fw-4 font-33">当前余额(元)</div>
				<div class="btn mt24">
					<button @click="curType = 'recharge'">充值</button>
					<button @click="curType = 'withdraw'">立即提现</button>
				</div>

				<div @click="curType = 'bind'" class="bind">提现账户</div>
			</div>
			<!-- 积分 end -->

			<!-- 记录 start -->
			<div class="list-wrap">
				<div class="list-change fs16 font-66 flex mt16">
					<div :class="{ active: change_type == 0 }" @click="topChange(0)">
						全部
					</div>
					<div :class="{ active: change_type == 3 }" @click="topChange(3)">
						收入记录
					</div>
					<div :class="{ active: change_type == 4 }" @click="topChange(4)">
						支出记录
					</div>
				</div>

				<div class="list-con">
					<div style="min-height: 600px" v-loading="listLoading">
						<div v-if="arrs.length > 0 || listLoading">
							<div
								v-for="(item, index) in arrs"
								:key="index"
								class="list-item ptb32"
							>
								<div class="flex fs16 font-33">
									<div class="remark">
										{{ item.remark }}
									</div>
									<div
										class="change-score text-r fw-7"
										:style="{
											color: item.change_money > 0 ? '#F03C3B' : '#333333',
										}"
									>
										{{ item.change_money > 0 ? "+" : ""
										}}{{ item.change_money }}
									</div>
								</div>
								<div class="mt16 fs14 font-99">
									{{ item.create_time }}
								</div>
							</div>
						</div>
						<div
							v-else
							class="mt24 bgfff br4"
							style="overflow: hidden; padding-bottom: 60px"
						>
							<Empty></Empty>
						</div>
					</div>

					<!-- 分页 start -->
					<div class="page-wrap flex justify-end mt32">
						<el-pagination
							:background="true"
							layout="prev, pager, next"
							:total="total"
							:current-page="page"
							:page-size="pageSize"
							@current-change="change"
							hide-on-single-page
						>
						</el-pagination>
					</div>
					<!-- 分页 end -->
				</div>
			</div>
			<!-- 记录 end -->
		</div>
		<!-- 我的钱包 end -->

		<!-- 绑定提现账户 start -->
		<div
			v-if="curType == 'bind' || curType == 'wx' || curType == 'ali'"
			class="bind-wrap"
		>
			<!-- 面包屑 start -->
			<div
				class="bread flex align-center font-33 pb24"
				style="border-bottom: 1px solid #eeeeee"
			>
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'purse'"
						class="is-link"
						>我的钱包</el-breadcrumb-item
					>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'bind'"
						:class="{ 'is-link': curType == 'wx' || curType == 'ali' }"
						>绑定提现账户</el-breadcrumb-item
					><el-breadcrumb-item v-if="curType == 'wx' || curType == 'ali'"
						>绑定账号</el-breadcrumb-item
					>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->
			<div v-if="curType == 'bind'" class="bind fs16 font-33 mt24">
				<div class="tit fw-7">
					<img class="mr8" src="@/assets/list/item-con-tip.png" />
					提现账户
				</div>
				<div @click="curType = 'wx'" class="item justify-between ptb32">
					<div><img src="@/assets/public/wallet-wx.png" />微信</div>
					<div v-if="is_wx" class="fs12" style="color: #f40">已绑定</div>
				</div>
				<div @click="curType = 'ali'" class="item justify-between ptb32">
					<div><img src="@/assets/public/wallet-ali.png" />支付宝</div>
					<div v-if="is_ali" class="fs12" style="color: #f40">已绑定</div>
				</div>
			</div>

			<WalletBindWx
				v-if="curType == 'wx'"
				@operate="bindOperate"
				@toBack="toBack"
			></WalletBindWx>
			<WalletBindAli
				v-if="curType == 'ali'"
				@operate="bindOperate"
				@toBack="toBack"
			></WalletBindAli>
		</div>
		<!-- 绑定提现账户 end -->

		<!-- 提现 start -->
		<div v-if="curType == 'withdraw'" class="withdraw-wrap">
			<!-- 面包屑 start -->
			<div
				class="bread flex align-center font-33 pb24"
				style="border-bottom: 1px solid #eeeeee"
			>
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'purse'"
						class="is-link"
						>我的钱包</el-breadcrumb-item
					>
					<el-breadcrumb-item>提现</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<WalletWithdraw @operate="operate"></WalletWithdraw>
		</div>
		<!-- 提现 end -->

		<!-- 充值 start -->
		<div v-if="curType == 'recharge'" class="recharge">
			<!-- 面包屑 start -->
			<div
				class="bread flex align-center font-33 pb24"
				style="border-bottom: 1px solid #eeeeee"
			>
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'purse'"
						class="is-link"
						>我的钱包</el-breadcrumb-item
					>
					<el-breadcrumb-item>充值</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->
			<WalletRecharge @operate="operate"></WalletRecharge>
		</div>
		<!-- 充值 end -->
	</div>
</template>
<script>
import WalletBindWx from "@/components/WalletBindWx.vue";
import WalletBindAli from "@/components/WalletBindAli.vue";
import WalletWithdraw from "@/components/WalletWithdraw.vue";
import WalletRecharge from "@/components/WalletRecharge.vue";
export default {
	name: "Wallet",
	components: {
		WalletBindWx,
		WalletBindAli,
		WalletWithdraw,
		WalletRecharge,
	},
	data() {
		return {
			// 当前在那里 purse 钱包; withdraw 提现; recharge 充值;
			// 					 bind 绑定提现账户; wx 绑定微信; ali 绑定支付宝;
			curType: "purse",
			moeny: 0, // 余额
			withdraw_handling_fee: 0, // 手续费
			change_type: 0, // 请求的订单类别
			id: "",
			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,

			is_wx: false,
			is_ali: false,
		};
	},
	created() {
		let { curType = "purse" } = this.$route.params;
		this.curType = curType;
		this.getData();
		this.getList();
		this.bindOperate(true);
		this.id = this.$route.query.id;
	},
	watch: {
		$route() {
			this.id = this.$route.query.id;
		},
		id() {
			if (this.id == 5) {
				this.curType = "recharge";
				//	console.log("重新请求" + this.id + "的数据");
			}
		},
	},
	methods: {
		// 绑定的操作
		bindOperate(first = false) {
			if (!first) {
				this.curType = "bind";
			}
			this.$API
				.getWithdrawAccount({
					account_type: 1, //  1 微信 2 支付宝 3 银行卡。
				})
				.then((res) => {
					if (res.code == 1) {
						if (
							res.data.true_name != "" &&
							res.data.qrcode != "" &&
							res.data.account_id != ""
						) {
							this.is_wx = true;
						}
					}
				});
			this.$API
				.getWithdrawAccount({
					account_type: 2, //  1 微信 2 支付宝 3 银行卡。
				})
				.then((res) => {
					if (res.code == 1) {
						if (
							res.data.true_name != "" &&
							res.data.qrcode != "" &&
							res.data.account_id != ""
						) {
							this.is_ali = true;
						}
					}
				});
		},
		toBack() {
			this.curType = "withdraw";
		},
		operate(e) {
			if (e.checked) {
				this.curType = e.checked;
			}

			if (e.type == "recharge") {
				this.curType = "purse";
			}

			this.page = 1;
			this.change_type = 0;
			this.getData();
			this.getList();
			//this.curType = "withdraw";
		},
		// 获取信息
		getData() {
			// 获取金额信息
			this.$API
				.getMyMoney()
				.then((res) => {
					if (res.code == 1) {
						// 余额
						this.moeny = res.data.user_money;
						// 手续费
						this.withdraw_handling_fee = res.data.withdraw_handling_fee;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 切换
		topChange(change_type) {
			if (this.listLoading) {
				return false;
			}

			this.change_type = change_type;
			this.page = 1;
			this.getList();
		},
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},
		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize, change_type } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.getMoneyDetail({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
					change_type,
					is_month: 0,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}
}

.Wallet {
	@extend .w100;
	@extend .h100;

	.purse {
		.tit {
			border-bottom: 1px dashed #eeeeee;

			.right {
				cursor: pointer;
				color: #4177dd;
			}
		}

		.nav {
			background: url(../../assets/imgs/wallet-bg.png) no-repeat 100% 100%;

			.money {
				color: #ff8300;
			}

			.btn {
				button {
					width: 160px;
					height: 40px;
					line-height: 40px;
					background: #ff8300;
					border-radius: 44px;
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;

					&:first-child {
						background: transparent;
						border: 1px solid #ff8300;
						color: #ff8300;
						margin-right: 72px;
					}
				}
			}

			.bind {
				cursor: pointer;
				position: absolute;
				right: 0px;
				top: 18px;
				color: #ff8300;
				padding: 8px 20px;
				border-radius: 100px 0 0 100px;
				background-color: rgba(255, 255, 255, 0.3);
			}
		}

		.list-wrap {
			.list-change {
				border-bottom: 1px solid #eeeeee;

				div {
					cursor: pointer;
					padding: 24px;
					position: relative;
					left: 0;
					top: 0;

					&:after {
						content: "";
						display: none;
						width: 32px;
						height: 4px;
						background: #ff8300;
						border-radius: 2px;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}

				.active {
					color: #ff8300;
					font-weight: 700;

					&:after {
						display: block;
					}
				}
			}

			.list-con {
				.list-item {
					border-bottom: 1px solid #eeeeee;

					> .flex {
						align-items: baseline;

						.remark {
							flex: 2;
						}

						.change-score {
							flex: 1;
						}
					}
				}
			}
		}
	}

	.bind-wrap {
		.bind {
			div {
				display: flex;
				align-items: center;
			}

			.tit {
				img {
					width: 18px;
					height: 11px;
				}
			}

			.item {
				border-bottom: 1px solid #eeeeee;
				cursor: pointer;

				img {
					width: 40px;
					height: 40px;
					margin-right: 24px;
				}
			}
		}
	}
}
</style>
